import GATSBY_COMPILED_MDX from "/Users/tedsczelecki/Documents/projects/tedsczelecki.com/src/content/projects/walmart-blackweb.mdx";
import React from 'react';
import {Badge, Button} from '@chakra-ui/react';
import VStack from '../../components/Global/VStack';
import HStack from '../../components/Global/HStack';
import ContentLayout from '../../components/Layout/ContentLayout';
import ContentBox from '../../components/Global/ContentBox';
import WorkImage from '../../components/Work/Image';
import {ORANGE, PINK, tagColors, TRANSPARENT} from '../../constants';
import HomepageHero from '../../components/Hero/Homepage';
import {SlArrowRight} from "react-icons/sl";
var ProjectPage = function ProjectPage(_ref) {
  var _data$mdx$frontmatter, _data$mdx, _data$mdx2, _images$0$publicURL, _images$;
  var data = _ref.data, children = _ref.children;
  var _ref2 = (_data$mdx$frontmatter = data === null || data === void 0 ? void 0 : (_data$mdx = data.mdx) === null || _data$mdx === void 0 ? void 0 : _data$mdx.frontmatter) !== null && _data$mdx$frontmatter !== void 0 ? _data$mdx$frontmatter : {}, description = _ref2.description, externalLink = _ref2.externalLink, images = _ref2.images, subTitle = _ref2.subTitle, tags = _ref2.tags, title = _ref2.title;
  console.log('IMAGES', data === null || data === void 0 ? void 0 : (_data$mdx2 = data.mdx) === null || _data$mdx2 === void 0 ? void 0 : _data$mdx2.frontmatter);
  return React.createElement(ContentLayout, {
    title: title + " " + (subTitle ? "- " + subTitle : ''),
    description: description
  }, React.createElement(VStack, {
    gap: {
      base: '1rem',
      sm: '3rem'
    }
  }, React.createElement(HomepageHero, {
    imageUrl: (_images$0$publicURL = images === null || images === void 0 ? void 0 : (_images$ = images[0]) === null || _images$ === void 0 ? void 0 : _images$.publicURL) !== null && _images$0$publicURL !== void 0 ? _images$0$publicURL : '',
    rotateImage: false,
    subTitle: subTitle,
    title: title !== null && title !== void 0 ? title : ''
  }), React.createElement(ContentBox, {
    maxWidth: "800px"
  }, React.createElement(VStack, {
    gap: "7"
  }, React.createElement(VStack, {
    gap: "7"
  }, children), React.createElement(Button, {
    colorScheme: ORANGE.replace('.400', ''),
    onClick: function onClick() {
      return window.open(externalLink, '_blank');
    },
    rightIcon: React.createElement(SlArrowRight, null),
    size: "lg",
    variant: "outline"
  }, "Check it out"), tags && React.createElement(HStack, null, tags === null || tags === void 0 ? void 0 : tags.map(function (tag) {
    var _tagColors;
    return React.createElement(Badge, {
      colorScheme: (_tagColors = tagColors === null || tagColors === void 0 ? void 0 : tagColors[tag !== null && tag !== void 0 ? tag : '']) !== null && _tagColors !== void 0 ? _tagColors : PINK,
      key: tag,
      fontSize: "lg"
    }, tag);
  })))), React.createElement(ContentBox, {
    as: VStack,
    gap: "0"
  }, React.createElement(VStack, {
    px: "8",
    width: "100%",
    gap: "8rem"
  }, images === null || images === void 0 ? void 0 : images.slice(1).map(function (images, i) {
    return (images === null || images === void 0 ? void 0 : images.publicURL) && React.createElement(WorkImage, {
      alt: title + "  thumbnail " + (i + 1),
      color: TRANSPARENT,
      glitchImage: false,
      key: images.publicURL,
      src: images.publicURL,
      size: "100%"
    });
  })))));
};
var pageQuery = "2656414520";
ProjectPage
export default function GatsbyMDXWrapper(props) {
  return React.createElement(ProjectPage, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
